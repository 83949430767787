import moment from 'moment';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Message as IMessage } from '../../services/buildfire/rdb/messages';
import { MessageContext } from '../../context/MessageContext';

interface MessageProps {
  message: IMessage;
  messageType: 'incoming' | 'outgoing';
  showTime: boolean;
  firstInBundle: boolean;
  lastInBundle: boolean;
}

const Container = styled.div<{ $messageType: MessageProps['messageType'] }>`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 42px);
  align-items: ${(props) =>
    props.$messageType === 'incoming' ? 'flex-start' : 'flex-end'};
  align-self: ${(props) =>
    props.$messageType === 'incoming' ? 'flex-start' : 'flex-end'};
  margin-bottom: 4px;
`;

const MessageBox = styled.div<{
  $messageType: MessageProps['messageType'];
  $firstInBundle: boolean;
  $lastInBundle: boolean;
}>`
  background-color: ${(props) =>
    props.$messageType === 'incoming' ? '#f0f0f0' : 'var(--mdc-theme-primary)'};
  color: ${(props) =>
    props.$messageType === 'incoming'
      ? 'var(--mdc-theme-text-primary-on-light)'
      : `var(--mdc-theme-text-primary-on-dark)`};
  border-radius: 18px;
  padding: 8px 12px 4px 12px;
  word-break: break-word;

  ${(props) =>
    props.$messageType === 'incoming'
      ? `
        border-top-left-radius: ${props.$firstInBundle ? '18px' : '4px'};
        border-bottom-left-radius: ${props.$lastInBundle ? '18px' : '4px'};
      `
      : `
        border-top-right-radius: ${props.$firstInBundle ? '18px' : '4px'};
        border-bottom-right-radius: ${props.$lastInBundle ? '18px' : '4px'};
      `}
`;

const Content = styled.div<{ $messageType: MessageProps['messageType'] }>`
  font-size: 14px;
  & a {
    color: var(--ion-color-primary);
    color: ${(props) =>
      props.$messageType === 'incoming'
        ? 'var(--ion-color-primary)'
        : `var(--mdc-theme-text-primary-on-dark)`};
  }
  & p {
    margin: 2px;
  }

  & img {
    cursor: pointer;
  }
`;

const Time = styled.div`
  margin: 2px 12px;
  font-size: 10px;
`;

const Message: React.FC<MessageProps> = ({
  message,
  messageType,
  showTime,
  firstInBundle,
  lastInBundle,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { setSelectedImage } = useContext(MessageContext);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const imgs = containerRef.current.querySelectorAll('img');
    const unsubs: (() => void)[] = [];
    const imgSrcs: string[] = [];
    imgs.forEach((img) => {
      const src = img.src;
      imgSrcs.push(src);
      const onClick = () => {
        setSelectedImage(src);
      };
      img.addEventListener('click', onClick);
      unsubs.push(() => img.removeEventListener('click', onClick));
    });
    return () => {
      unsubs.forEach((fn) => fn());
    };
  }, []);

  return (
    <Container $messageType={messageType}>
      <MessageBox
        $messageType={messageType}
        $firstInBundle={firstInBundle}
        $lastInBundle={lastInBundle}
      >
        <Content
          ref={containerRef}
          $messageType={messageType}
          dangerouslySetInnerHTML={{ __html: message.content }}
        />
      </MessageBox>
      {showTime && <Time>{moment(message.timestamp).format('LT')}</Time>}
    </Container>
  );
};

export default Message;
