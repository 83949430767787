import { getDatabase } from 'firebase/database';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  CaseStatus,
  acceptCase,
  markAsViewed,
} from '../../../services/buildfire/rdb/cases';
import { useFirebase } from '../../../services/firebase';
import ExpandingButton from '../../common/ExpandingButton';
import { useLinkButtonProps } from '../../common/LinkButton';
import CaseInfo, { CaseInfoProps } from './CaseInfo';
import CaseInfoHeader from '../CaseInfoHeader';
import {
  IonHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import User from '../../../views/User';
import { warning } from 'ionicons/icons';

interface AdminCaseInfoProps extends CaseInfoProps {}

const ReportContainer = styled.div`
  padding: 0 32px 32px 32px;
  text-align: center;
`;
const Segment = styled(IonSegment)`
  display: flex;
  justify-content: space-around;
`;

function useViewed(caseId: string) {
  const { app } = useFirebase();
  React.useEffect(() => {
    const database = getDatabase(app);
    markAsViewed(database, caseId);
  }, [caseId]);
}

const AdminCaseInfo: React.FC<AdminCaseInfoProps> = ({ case: c, ...props }) => {
  useViewed(c.id);

  const { app, currentUser, userMetadata } = useFirebase();
  const [present] = useIonToast();
  const match = useRouteMatch();
  const reportPath = generatePath(`${match.path}/report`, {
    caseId: c.id,
  });
  const linkButtonProps = useLinkButtonProps();
  const [view, setView] = React.useState<'caseInfo' | 'userInfo'>('caseInfo');
  const isClaimed = !!c.acceptedBy;
  const isClaimedByOther = isClaimed && c.acceptedBy?.uid !== currentUser?.uid;
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <CaseInfoHeader case={c} actionItems={props.actionItems} isAdmin />

      <IonHeader>
        <IonToolbar>
          <Segment value={view}>
            <IonSegmentButton
              value="caseInfo"
              onClick={() => setView('caseInfo')}
              style={{ maxWidth: '200px' }}
            >
              <IonLabel>Case Info</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="userInfo"
              onClick={() => setView('userInfo')}
              style={{ maxWidth: '200px' }}
            >
              <IonLabel>User Info</IonLabel>
            </IonSegmentButton>
          </Segment>
        </IonToolbar>
      </IonHeader>

      {view === 'caseInfo' && (
        <CaseInfo
          case={c}
          // bannerContent={
          //   isClaimed && <AssignedBanner currentUser={currentUser!} case={c} />
          // }
          {...props}
        >
          <ReportContainer>
            {/* {c.status === CaseStatus.OPEN && !c.acceptedBy && (
              <ExpandingButton
                variant="secondary"
                size="large"
                role={linkButtonProps.role}
                onClick={async () => {
                  try {
                    const db = getDatabase(app);
                    await acceptCase(db, c.id, {
                      uid: currentUser!.uid,
                      displayName: currentUser!.displayName!,
                      initials: 'TODO',
                      credentials: userMetadata?.credentials || '',
                    });
                    present('Case claimed', 4000);
                  } catch (e) {
                    console.error(e);
                    present({
                      message: 'Failed to claim case, please try again',
                      icon: warning,
                      color: 'danger',
                      duration: 6000,
                    });
                  }
                }}
              >
                Claim
              </ExpandingButton>
            )} */}
            {c.status === CaseStatus.OPEN && !isClaimedByOther && (
              <ExpandingButton
                variant="secondary"
                size="large"
                role={linkButtonProps.role}
                onClick={async (e) => {
                  try {
                    setIsLoading(true);
                    if (c.acceptedBy?.uid !== currentUser!.uid) {
                      const db = getDatabase(app);
                      await acceptCase(db, c.id, {
                        uid: currentUser!.uid,
                        displayName: currentUser!.displayName!,
                        signature: userMetadata?.signature || '',
                        photoUrl: currentUser!.photoURL || '',
                      });
                    }
                    // present('Case claimed', 4000);
                    linkButtonProps.onClick(`${reportPath}/create`)(e);
                  } catch (e) {
                    console.error(e);
                    present({
                      message: 'Failed to assign case, please try again',
                      icon: warning,
                      color: 'danger',
                      duration: 6000,
                    });
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                Enter report
              </ExpandingButton>
            )}
            {c.status === CaseStatus.SIGNED_OFF && (
              <ExpandingButton
                variant="primary"
                size="large"
                role={linkButtonProps.role}
                onClick={linkButtonProps.onClick(reportPath)}
              >
                View Report
              </ExpandingButton>
            )}
          </ReportContainer>
        </CaseInfo>
      )}

      {view === 'userInfo' && (
        <User
          user={{
            displayName: c.user.displayName,
            email: c.user.email,
            uid: c.uid,
          }}
          showHeader={false}
          baseUrlPath="/admin/users/"
          viewCasesInNewTab
          isAdmin
        />
      )}
    </>
  );
};

export default AdminCaseInfo;
