import React from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import styled from 'styled-components';
import { UserMetadataWithId, getUserMetadata } from '../services/firebase/user';
import { IonContent } from '@ionic/react';
import moment from 'moment';
import ExpandingButton from '../components/common/ExpandingButton';
import { useLinkButtonProps } from '../components/common/LinkButton';
import InfoSection, { LineItem } from '../components/case/caseInfo/InfoSection';
import { Database, getDatabase } from 'firebase/database';
import { useFirebase } from '../services/firebase';
import useFetch from '../hooks/useFetch';
import { LOADING_STATE } from '../util/loading';
import LoadingScreen from '../components/common/LoadingScreen';
import {
  getInvoices,
  getParentAccount,
  getSubaccounts,
} from '../api/cloudFunctions';
import { Functions, getFunctions } from 'firebase/functions';
import UserListItem from '../components/users/UserListItem';
import memoizePromiseFn from '../util/memoizePromiseFn';
import BillSummary from '../components/user/BillSummary';
import UserHeader from '../components/user/UserHeader';

export interface UserProps {
  user: Partial<UserMetadataWithId> | FirebaseUser;
  showHeader?: boolean;
  baseUrlPath?: string;
  viewCasesInNewTab?: boolean;
  isAdmin: boolean;
}

const Content = styled.div`
  padding: 32px;
`;

const isFirebaseUser = (
  user: Partial<UserMetadataWithId> | FirebaseUser
): user is FirebaseUser => !!(user as any).metadata;

const getCreationTime = (user: Partial<UserMetadataWithId> | FirebaseUser) => {
  if (isFirebaseUser(user)) {
    return user.metadata.creationTime || '';
  }

  return user.createdAt || '';
};

const formatDate = (date: string) => moment(date).format('MMM D, yyyy');

const fetchUserData = (
  userId: string,
  isAdmin: boolean,
  database: Database,
  fns: Functions
) => {
  // Only grab invoice list if admin, parent accounts don't have access
  const getInvoicesFn = isAdmin ? getInvoices : async () => ({ invoices: [] });
  return Promise.all([
    getParentAccount(fns, { userId: userId })
      .then((result) => result?.parentUser)
      // Don't throw on error since not all accounts have this
      .catch((e) => console.warn(e)),
    getSubaccounts(fns, { uid: userId }).then(
      (result) => result?.data.users || []
    ),
    getUserMetadata(database, userId)
      // Don't throw on error since not all accounts have this
      .catch((e) => {
        console.warn(e);
        return undefined;
      }),
    getInvoicesFn(fns, { userId }).then((result) => result.invoices),
  ]).then(([parentUser, subaccounts, userMetadata, invoices]) => ({
    parentUser,
    subaccounts,
    userMetadata,
    invoices,
  }));
};

const memoizedFetchUserData = memoizePromiseFn(
  fetchUserData,
  (userId) => userId
);

const User: React.FC<UserProps> = ({
  user,
  showHeader = true,
  baseUrlPath = '',
  viewCasesInNewTab,
  isAdmin,
}) => {
  const linkButtonProps = useLinkButtonProps({ newWindow: viewCasesInNewTab });
  const { app } = useFirebase();

  const { error, loadingStatus, data } = useFetch(
    async () => {
      if (!user.uid) {
        return;
      }

      const database = getDatabase(app);
      const fns = getFunctions(app);

      return memoizedFetchUserData(user.uid, isAdmin, database, fns);
    },
    () => {},
    [user.uid]
  );
  const [updatedUserMetadata, setUpdatedUserMetadata] =
    React.useState<Partial<UserMetadataWithId>>();
  const userMetadata = updatedUserMetadata || data?.userMetadata;
  const invoicingDisabled = userMetadata?.invoicingDisabled;

  const content = (
    <>
      <Content>
        <InfoSection title="User info">
          <LineItem name="Name" description={user.displayName || 'N/A'} />
          <LineItem name="Email" description={user.email || 'N/A'} />
          <LineItem
            name="Joined"
            description={formatDate(getCreationTime(user))}
          />
          {data?.parentUser && (
            <LineItem
              name="Hospital"
              description={`${data.parentUser.displayName} (${data.parentUser.email})`}
            />
          )}
          <LineItem
            name="Invoicing manually disabled"
            description={invoicingDisabled ? 'Yes' : 'No'}
          />
        </InfoSection>

        {isAdmin && data?.invoices && (
          <InfoSection title="Billing summary">
            <BillSummary uid={user.uid!} invoices={data.invoices} />
          </InfoSection>
        )}

        {data?.subaccounts && data.subaccounts.length > 0 && (
          <InfoSection title="Subaccounts">
            {data.subaccounts.map((account) => (
              <UserListItem user={account} />
            ))}
          </InfoSection>
        )}

        <ExpandingButton
          variant="primary"
          size="large"
          role={linkButtonProps.role}
          onClick={linkButtonProps.onClick(`${baseUrlPath}${user.uid}/cases`)}
        >
          View cases
        </ExpandingButton>
      </Content>
    </>
  );
  return (
    <>
      {loadingStatus === LOADING_STATE.LOADED && showHeader && (
        <UserHeader
          user={user}
          isAdmin={isAdmin}
          userMetadata={userMetadata}
          updateUser={setUpdatedUserMetadata}
        />
      )}

      <IonContent>
        <LoadingScreen loadingStatus={loadingStatus} error={error}>
          {content}
        </LoadingScreen>
      </IonContent>
    </>
  );
};

export default User;
