import { Database, getDatabase, push, ref, update } from 'firebase/database';
import React from 'react';
import styled from 'styled-components';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import ReactQuill from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';

import Button from '../../mdc/Button';
import { Message } from '../../services/buildfire/rdb/messages';
import { useFirebase } from '../../services/firebase';
import {
  getFileDownloadUrl,
  uploadImages,
} from '../../services/firebase/storage';
import { getStorage } from 'firebase/storage';
import { isDesktop } from '../../util/device';
import { getExtension } from '../../util/file';

Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/imageUploader', ImageUploader);

interface MessageInputBoxProps {
  caseId: string;
  disabled: boolean;
}

enum ChatState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

const Form = styled.form`
  display: flex;
  align-items: stretch;
  max-height: 300px;
  overflow: auto;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // padding: 8px;
`;

const SubmitButton = styled(Button)`
  align-self: stretch;
  height: auto;
  margin: 0 0 0 8px;
  span {
    font-family: 'Material Icons' !important;
  }
`;

const Input = styled(ReactQuill)`
  flex-grow: 1;
  word-break: break-word;
`;

const writeMessage = (database: Database, caseId: string, message: Message) => {
  return push(ref(database, `messages/${caseId}`), message);
};

const updateCase = async (
  database: Database,
  caseId: string,
  message: Message
) => {
  // Write last modified status
  await update(ref(database, `cases/${caseId}`), {
    lastModified: Date.now(),
    lastMessage: message,
    lastMessageStatus: 'unread',
    [`conversation/${message.uid}`]: true,
  });

  // Add user to conversation if not already there
  // const conversationRef = ref(database, `cases/${caseId}/conversation/${message.uid}`)
  // await set(conversationRef, true)
};

interface QuillInputProps {
  content: string;
  setContent: (str: string) => void;
  setPlainText: (str: string) => void;
  handleImageUpload: (file: File) => Promise<string>;
  handleSubmit: () => Promise<any>;
}
class QuillInput extends React.Component<QuillInputProps, any> {
  modules = {
    // toolbar: [['image', image]],
    toolbar: {
      container: '#message-toolbar',
      handlers: {
        image: true,
      },
    },
    imageUploader: {
      upload: this.props.handleImageUpload,
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: function (range, context) {
            const desktop = isDesktop();
            if (desktop) {
              return false; // will get handled by keyup handler
            }

            // Let quill insert a newline
            return true;
          },
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
    magicUrl: true,
  };

  formats = ['image', 'link', 'imageBlot'];

  ref: ReactQuill | null = null;

  setRef = (ref: ReactQuill) => {
    this.ref = ref;
  };

  render() {
    const { content, setContent, setPlainText, handleSubmit } = this.props;
    return (
      <Input
        ref={this.setRef}
        value={content}
        theme="snow"
        modules={this.modules}
        formats={this.formats}
        onChange={(value, _delta, source) => {
          // Handle case when Quill still processes "Enter" keypress
          // after form is submitted, which would leave us with a
          // newline left in the message box
          if (value === '<p><br></p>' && source === 'api') {
            return;
          }

          setContent(value);

          const plainText = this.ref?.unprivilegedEditor?.getText();
          setPlainText(plainText || '');
        }}
        bounds="#message-input-form"
        onKeyUp={(e) => {
          const enterPressed = e.which === 13;
          const desktop = isDesktop();
          if (enterPressed && !e.shiftKey && desktop) {
            handleSubmit();
          }
        }}
      />
    );
  }
}

const uniqueFileName = (f: File) => {
  const id = `${Date.now()}`;
  const ext = getExtension(f.name);
  const name = `message-upload-${id}${ext}`;
  return new File([f], name, { type: f.type });
};

const MessageInputBox: React.FC<MessageInputBoxProps> = ({
  caseId,
  disabled,
}) => {
  const [content, setContent] = React.useState('');
  const [plainText, setPlainText] = React.useState('');
  const [state, setState] = React.useState<ChatState>(ChatState.IDLE);
  const [errorText, setErrorText] = React.useState('');
  const { currentUser, app } = useFirebase();
  const isEmpty = !Boolean(content) || content === '<p><br></p>';

  const handleSubmit = async (e?: any) => {
    e?.preventDefault?.();

    if (isEmpty) {
      return;
    }

    const message = {
      content,
      plainText,
      type: 'text',
      timestamp: Date.now(),
      uid: currentUser!.uid,
      name: currentUser!.displayName,
    } as const;
    setState(ChatState.LOADING);

    const database = getDatabase(app);
    return Promise.all([
      writeMessage(database, caseId, message as any),
      updateCase(database, caseId, message as any),
    ])
      .then(() => {
        setErrorText('');
        setState(ChatState.IDLE);
      })
      .catch((e) => {
        setState(ChatState.ERROR);
        setErrorText(e.message);
      })
      .finally(() => {
        setContent('');
      });
  };

  async function handleImageUpload(file) {
    try {
      const storage = getStorage(app);
      // Need to make the filename unique so we don't overwrite old files
      // with the same name
      const fileToUpload = uniqueFileName(file);
      const [fileName] = await uploadImages(
        storage,
        [fileToUpload],
        currentUser!.uid
      );

      const fileUrl = await getFileDownloadUrl(
        storage,
        fileName,
        currentUser!.uid
      );

      return fileUrl;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  return (
    <Form id="message-input-form" onSubmit={handleSubmit}>
      <div id="message-toolbar">
        <button className="ql-image" />
      </div>
      <QuillInput
        content={content}
        setContent={setContent}
        setPlainText={setPlainText}
        handleImageUpload={handleImageUpload}
        handleSubmit={handleSubmit}
      />
      <SubmitButton
        tabIndex={1}
        disabled={state === ChatState.LOADING || disabled || isEmpty}
        type="submit"
      >
        <span className="material-icons mdc-send__icon">send</span>
      </SubmitButton>
      {errorText && <p>{errorText}</p>}
    </Form>
  );
};

export default MessageInputBox;
