import React from 'react';
import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { Case } from '../../../services/buildfire/rdb/cases';
import { Report } from '../../../services/buildfire/rdb/reports';
import Typography from '../../common/Typography';
import CaseInfoHeader from '../CaseInfoHeader';
import PatientInfo from '../PatientInfo';
import SitesList from '../site/SitesList';
import SubmitterInfo from '../SubmitterInfo';
import ReportItemInfo from './ReportItemInfo';
import PaymentInfo from '../caseInfo/PaymentInfo';

interface ReportProps {
  case: Case;
  report: Report;
  clinicName: string;
  clinicEmail: string;
  isAdmin: boolean;
}

const Padding = styled.div`
  padding: 0 16px;
`;

const ReportContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const ReportComponent: React.FC<ReportProps> = ({
  case: c,
  report,
  clinicEmail,
  clinicName,
  isAdmin,
  children,
  ...props
}) => {
  if (!report) {
    return (
      <Typography variant="primary" fontSize="24px">
        Report not found for case {c.id}
      </Typography>
    );
  }
  return (
    <>
      <CaseInfoHeader
        case={c}
        actionItems={null}
        isAdmin={isAdmin}
        showStatusBadge={false}
      />
      <IonContent>
        <Padding>
          <SubmitterInfo
            case={c}
            clinicName={clinicName}
            clinicEmail={clinicEmail}
          />
          <PaymentInfo case={c} />
          <PatientInfo case={c} includeName />
        </Padding>

        <SitesList case={c} userId={c.uid} sites={c.sites} />
        <ReportContainer>
          {report?.items.map((item, i) => (
            <ReportItemInfo key={i} item={item} index={i} />
          ))}

          <Padding>{children}</Padding>
        </ReportContainer>
      </IonContent>
    </>
  );
};

export default ReportComponent;
