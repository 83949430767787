import React from 'react';
import { useDialog } from '../../../../context/DialogContext';
import { Case } from '../../../../services/buildfire/rdb/cases';
import ModalLoader from '../../../common/ModalLoader';
import { addDebugMessage } from '../../../Debug';
import { isPlatform } from '@ionic/react';
import ActionItem from '../ActionItem';
import { document } from 'ionicons/icons';
import { httpsCallable, Functions, getFunctions } from 'firebase/functions';
import { useFirebase } from '../../../../services/firebase';
import { Share } from '@capacitor/share';
import { Report } from '../../../../services/buildfire/rdb/reports';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

interface DownloadPDFProps {
  case: Case;
}

const downloadPDF = async (fns: Functions, c: Case) => {
  // const userProfileOpt = await getUserProfile(c.uid);
  // if (O.isNone(userProfileOpt)) {
  //   throw new Error('Failed to retrieve user profile');
  // }
  // const userProfile = userProfileOpt.value;
  const fn = httpsCallable<any, any>(fns, 'generateReportPDF');
  const { data } = await fn({
    uid: c.uid,
    caseId: c.id,
    submitter: {
      email: c.user.email,
    },
  });
  return data.url;
};

export function useDownloadReport(c: Case, report?: Report) {
  const isDevice = isPlatform('android') || isPlatform('ios');
  const [isLoading, setIsLoading] = React.useState(false);
  const negated = React.useRef(false);
  const { openDialog } = useDialog();
  const { app } = useFirebase();
  const functions = getFunctions(app);

  const downloadReport = async () => {
    try {
      let signedUrl: string;
      let fileName = report?.fileName;
      if (!fileName) {
        setIsLoading(true);
        signedUrl = await downloadPDF(functions, c);
        setIsLoading(false);
      } else {
        const storage = getStorage(app);
        const r = ref(storage, fileName);
        signedUrl = await getDownloadURL(r);
      }

      if (!negated.current) {
        if (isDevice) {
          addDebugMessage({
            content: [
              'Initiating buildfire download',
              {
                uri: signedUrl,
                fileName: `pocket-pathologist-${c.caseNumber}.pdf`,
                path: '/pocket-pathologist/reports',
              },
            ],
          });

          try {
            const canShare = await Share.canShare();
            console.log(`canShare=${canShare}`);
            await Share.share({
              title: `Pocket Pathologist Case ${c.caseNumber}`,
              text: `Pocket Pathologist report for ${c.patient.first} ${c.patient.last}`,
              url: signedUrl,
              dialogTitle: 'Share',
            });
          } catch (e: any) {
            if (e?.message !== 'Share canceled') {
              addDebugMessage({ content: e });
              throw e;
            }
          }
        } else {
          window.open(signedUrl, '_blank');
        }
      }
      negated.current = false;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      openDialog(<div>{(e as any).message || e}</div>);
      negated.current = false;
    }
  };

  const negate = () => {
    setIsLoading(false);
    negated.current = true;
  };

  return { downloadReport, isLoading, negate };
}

const DownloadPDF: React.FC<DownloadPDFProps> = ({ case: c, ...props }) => {
  const { downloadReport, isLoading, negate } = useDownloadReport(c);
  return (
    <>
      <ActionItem
        icon={document}
        disabled={isLoading}
        onClick={downloadReport}
        label="Download Report"
      />
      {isLoading && <ModalLoader onClose={negate} />}
    </>
  );
};

export default DownloadPDF;
